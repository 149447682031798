import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import SEO from "../components/seo"
import TeamComponent from "../components/TeamComponent/TeamComponent"
import "../styles/global.scss"
import { unloadRecaptcha } from "../utils/Captcha"

const Team = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent languageSwitchSubpage="team">
      <SEO title="Team" />
      <MenuComponent
        selected={2}
        submenu={[
          { title: "Historie", url: "#historie" },
          { title: "Mitglieder", url: "#team" },
          { title: "Mitmachen", url: "/contact" },
        ]}
      />
      <h2
        className="underline-headline a-little-bit-of-margin-top"
        id="historie"
      >
        Historie
      </h2>
      <p className="left-side-margin a-little-bit-of-margin-top">
        Barokcity Rollerderby gründeten sich 2007, als zweite Rollerderby
        Mannschaft in Deutschland. Im Jahr 2015 wurde die Rollerderby Bundesliga
        in Detuschland gegründet. Des weiteren hat sich BCRD dem internationalen
        Rollerderby Dachverband WFTDA (Womans Flat Track Derby Association{" "}
        <a href="https://wftda.com/" target="_blank" rel="noreferrer nofollow">
          https://wftda.com/
        </a>
        ) angeschlossen und wurde nach einem Jahr Anwartschaft 2016 als
        Vollmitglied anerkannt. Wir stellen regelmäßig Spieler für die
        Nationalmannschaften.
      </p>
      <p className="left-side-margin a-little-bit-of-margin-top">
        BCRD gehört seit dem 29.03.2019 zum MTV Ludwigsburg:
        <br />
        Der MTV Ludwigsburg ist der mitgliederstärkste Verein in Ludwigsburg und
        einer der größten Sportvereine in Baden-Württemberg. Nicht dem
        Leistungssport verschrieben, schafft der MTV Jahr für Jahr viele neue
        Angebote für alle Altersschichten, die Spaß an der Bewegung haben.
      </p>
      <p className="left-side-margin a-little-bit-of-margin-top">
        Homepage:{" "}
        <a
          href="https://mtv-ludwigsburg.de"
          target="_blank"
          rel="noreferrer nofollow"
        >
          https://mtv-ludwigsburg.de
        </a>
      </p>
      <TeamComponent />
    </LayoutComponent>
  )
}

export default Team
